import React, { Component } from "react";
import { connect } from "react-redux";
import ApiLoader from "../common/loader";
import { getPDFLink } from "../redux/actions/violationActions";
import { Link } from "react-router-dom";
import { routes } from "../routings";

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCode: "",
    };
  }

  downloadReceipt = () => {
    this.props.getPDFLink(this.props.id, this.props.license_plate_number);
  };

  render() {
    console.log(this.props.propertyId);
    localStorage.setItem("propertyId", this.props.propertyId);
    return (
      <section className="main_section wrong_part">
        {this.props.loading && <ApiLoader />}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="left_part">
                <h1 className="some">Payment Successful!</h1>
                <p className="unsucces">Your payment was successful.</p>
                <h1 className="some tick">
                  <i className="far fa-check"></i>
                </h1>
                <p className="unsucces ur_unlock">Your unlock code is:</p>
                <h1 className="some copycode">{this.props.unlockCode}</h1>
                <div className="bottom_buttons error_button">
                  <a href={this.props.pdf} target="_blank">
                    <button className="yellow_btn">Download Receipt</button>
                  </a>

                  {/* <button onClick={this.downloadReceipt} className="yellow_btn">Download Receipt</button> */}
                  <a href={routes.home.path} className="default">
                    Go To Home
                  </a>
                  {/* <Link to="/ab/gps/web-stage" className="default">Go To Home</Link> */}
                </div>

                <div className="bottom_buttons error_button">
                  <a href={routes.removalInstruction.path} target="_blank">
                    <button className="yellow_btn">Removal Instruction</button>
                  </a>
                  <a target="_blank" href={this.props.bootRemovalVideoLink}>
                    <button className="yellow_btn">Removal Video</button>
                  </a>
                </div>

                <div className="payment-success-steps">
                  {/* <p><strong>Step 1:</strong> Scan QR code or go to the URL (website) located on the citation.</p> */}
                  {/* <p><strong>Step 2:</strong> Enter in your license plate and review the violation.</p>
                                    <p><strong>Step 3:</strong> Agree to the terms and make the payment.</p>
                                    <p><strong>Step 4:</strong> Use the onetime code received to unlock the lock box located at the booting station and retrieve the boot key.</p>
                                    <p><strong>Step 5:</strong> Use the key to unlock the boot (make sure the lock pops up) Then pull the boot apart.</p>
                                    <p><strong>Step 6:</strong>Return the boot and key to the boot station, ensure the lockbox with the returned key is secure and the boot is placed back on the station and secure by pressing the locking mechanism down.</p>
                                    <p><strong>Step 7:</strong> Take a picture of all items secured and send to parking@gpsvcs.com.</p> */}
                  {/* NOTICE - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
                  {/* <p>Help Link: <a href="https://youtu.be/HIFC_HO17nU">https://youtu.be/HIFC_HO17nU</a></p> */}
                  <p>
                    If you would like to dispute the parking violation, please
                    email{" "}
                    <a href="mailto: parking@gpsvcs.com">parking@gpsvcs.com</a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.violation.loading,
});

const dispatchers = {
  getPDFLink,
};

export default connect(mapStateToProps, dispatchers)(PaymentSuccess);
