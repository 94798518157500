import HomeComponent from "./components/home";
import UnpaidViolation from "./components/unpaidViolation";
import PastViolations from "./components/pastViolations";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Link, Redirect, Route, Switch } from "react-router-dom";
import ViolationDetails from './components/violationDetails';
import PaypalPayment from "./components/paypal";
import { ToastContainer } from "react-toastify";
import PaymentSuccess from "./components/paymentSuccess";
import PageNotFound from "./components/404";
import ContactUS from "./components/contactUs";
import RemovalInstruction from "./components/RemovalInstruction";
import {routes} from "./routings";

function App() {

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <div className="App">
          
          {/* Header Starts */}
          <section className="header">
            <div className="top_head">
              <div className="container">
                <div className="row">
                  <div className="col-md-10">
                    <div className="logo">
                      {/* <a href="/"><h2>GPS</h2></a> */}
                      <a href={routes.home.path}><h2>GPS</h2></a>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="question_mark">
                    <a href={routes.contactUs.path}><h2>?</h2></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Header Ends */}

          {/* Main Section Starts */}


          <Switch>
            <Route
              path={routes.home.path}
              exact
              component={HomeComponent}
            />
        
            <Route
              path={routes.unpaidViolation.path}
              component={UnpaidViolation}
            />

            <Route
              path={routes.pastViolations.path}
              component={PastViolations}
            />

            <Route
              path={routes.violationDetails.path}
              component={ViolationDetails}
            />

            <Route
              path={routes.payPal.path}
              component={PaypalPayment}
            />

            <Route
              path={routes.success.path}
              component={PaymentSuccess}
            />

            <Route
              path={routes.contactUs.path}
              component={ContactUS}
            />
            
            <Route
              path={routes.removalInstruction.path}
              component={RemovalInstruction}
            />

            <Route
                path={routes.pageNotFound.path}
                component={PageNotFound}
            />
            <Redirect to={routes.home.path} />
          </Switch>

          {/* Main Section Ends */}

          {/* Footer Starts */}
          <footer>
            <div className="footer_main">
              <div className="container">
                <div className="row">
                  <div className="col-md-10">
                    <p className="gpsvs">© 2021 Gpsvcs - All Rights Reserved.</p>
                  </div>
                  <div className="col-md-2">
                  <a href={routes.contactUs.path}>
                    <div className="need_help">
                      <p>Need Help ?</p>
                      <i className="fas fa-play"></i>
                    </div>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </footer>
          {/* Footer Ends */}

        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
